import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { RichText } from 'prismic-reactjs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  tabs: {
    flexBasis: '100%',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '50%',
    },
  },
  tab: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '2rem',
    padding: 0,
    '& span': {
      alignItems: 'flex-start',
    },
  },
  tabPanelWrapper: {
    flexBasis: '100%',
    paddingTop: '50px',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '50%',
      paddingTop: '0px',
    },
  },
  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
}));

function TabMenu(props) {
  const { data, primary } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root} id={primary.anchor_element}>
      <Tabs
        orientation="vertical"
        //variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        variant="fullWidth"
        className={classes.tabs}
        TabIndicatorProps={{ style: { background: 'transparent' } }}
      >
        {data.map((item, key) => {
          return (
            <Tab
              key={key}
              label={item.menu_item_title.text}
              className={classes.tab}
              {...a11yProps(key)}
            />
          );
        })}
      </Tabs>
      <div className={classes.tabPanelWrapper}>
        {data.map((item, key) => {
          return (
            <TabPanel
              key={key}
              value={value}
              index={key}
              className={classes.tabPanel}
            >
              <RichText render={item.content.raw} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
}

TabMenu.propTypes = {
  data: PropTypes.array.isRequired,
  primary: PropTypes.object,
};

export default TabMenu;
