import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ContactForm } from 'components/ui';

function ContactSection(props) {
  const { data } = props;
  return (
    <div className="spaced-grid-wrapper">
      <Box
        component={Grid}
        container
        display="flex"
        justifyContent="space-around"
      >
        <Grid item xs={12} lg={4}>
          <Box
            component="div"
            dangerouslySetInnerHTML={{
              __html: data.content.html,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ContactForm />
        </Grid>
      </Box>
    </div>
  );
}

ContactSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactSection;
