import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TitleText(props) {
  const { data } = props;
  console.log(data);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography component="h2" variant="h2" align="center">
          {data.title.text}
        </Typography>
      </Box>
      {data.content && data.content.text && (
        <Box width={{ xs: '100%', md: '50%' }} style={{ textAlign: 'center' }}>
          <div dangerouslySetInnerHTML={{ __html: data.content.html }}></div>
        </Box>
      )}
    </Box>
  );
}

TitleText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TitleText;
