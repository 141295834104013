import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

function Statistics(props) {
  const { data } = props;
  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {data.map((item, i) => {
          return (
            <Box
              key={i}
              width={{ xs: '50%', md: '25%' }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              mb={{ xs: 8, md: 0 }}
            >
              <Typography
                component="h1"
                variant="h1"
                align="center"
                className="font-weight-bold"
              >
                {item.title.text}
              </Typography>
              <Box width={{ xs: '100%', md: '75%' }}>
                <Typography component="p" variant="body1" align="center">
                  {item.caption.text}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

Statistics.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Statistics;
