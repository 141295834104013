import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

function FreeContentArea(props) {
  const { data } = props;
  console.log(data);
  return (
    <Container maxWidth="lg">
      {data.map((item, key) => {
        return (
          <Box
            key={key}
            component="div"
            dangerouslySetInnerHTML={{
              __html: item.content.html,
            }}
          />
        );
      })}
    </Container>
  );
}

FreeContentArea.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FreeContentArea;
