import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';

function TeamMembers(props) {
  const { data, primary } = props;
  console.log(primary);
  return (
    <Box id={primary.anchor_element}>
      <Container maxWidth="xl">
        <div className="spaced-grid-wrapper">
          <Box
            component={Grid}
            container
            justifyContent="space-between"
            spacing={4}
          >
            {data.map((item, key) => {
              return (
                <Grid key={key} item xs={12} md={6} lg={5}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb={{ xs: 10, md: 0 }}
                  >
                    <div>
                      <img src={item.image.url} alt={item.name.text} />
                    </div>
                    <Box py={8}>
                      <Typography
                        component="p"
                        variant="h3"
                        align="center"
                        className="text-uppercase"
                      >
                        {item.name.text}
                      </Typography>
                      {item.credentials && item.credentials.text && (
                        <Typography
                          component="p"
                          variant="caption"
                          align="center"
                          className="text-uppercase"
                        >
                          {item.credentials.text}
                        </Typography>
                      )}
                      <Typography
                        component="p"
                        variant="h6"
                        align="center"
                        className="text-uppercase"
                      >
                        {item.job_title.text}
                      </Typography>
                    </Box>
                    <div>
                      <Typography component="p" variant="body1">
                        <RichText render={item.profile.raw} />
                      </Typography>
                    </div>
                  </Box>
                </Grid>
              );
            })}
          </Box>
        </div>
      </Container>
    </Box>
  );
}

TeamMembers.propTypes = {
  data: PropTypes.array.isRequired,
  primary: PropTypes.object,
};

export default TeamMembers;
