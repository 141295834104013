import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import linkResolver from 'helpers/linkResolver';

const query = graphql`
  query {
    allPrismicNewsArticle {
      edges {
        node {
          uid
          type
          data {
            intro {
              text
            }
            image {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

function LatestNews(props) {
  const { data } = props;
  const display_num =
    data.number_of_articles > 0 ? data.number_of_articles : 1000;
  return (
    <StaticQuery
      query={`${query}`}
      render={data => (
        <div className="spaced-grid-wrapper">
          <Grid container spacing={4}>
            {data.allPrismicNewsArticle.edges
              .slice(0, display_num)
              .map((item, key) => {
                return (
                  <Grid key={key} item xs={12} sm={6} md={4}>
                    <Box
                      className="news-article-box"
                      display="flex"
                      flexDirection="column"
                    >
                      <div>
                        <img
                          src={`${item.node.data.image.url}&ar=2:1&fit=crop`}
                          alt={item.node.data.image.alt}
                          className="img"
                        />
                      </div>
                      <Box
                        px={5}
                        py={5}
                        display="flex"
                        flexGrow="1"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box pb={5}>
                          <Typography component="p" variant="body1">
                            {item.node.data.intro.text}
                          </Typography>
                        </Box>
                        <div>
                          <Link
                            to={linkResolver(item.node)}
                            className="text-no-underline btn btn-default"
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              color="textSecondary"
                            >
                              READ MORE
                            </Typography>
                          </Link>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
    />
  );
}

LatestNews.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LatestNews;
