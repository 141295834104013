import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

function ContactForm() {
  return (
    <div>
      <Box p={1} bgcolor="primary.main" mb={5}>
        <Typography color="textSecondary" component="p" variant="body2">
          MAKE AN ENQUIRY
        </Typography>
      </Box>
      <div>
        <form noValidate autoComplete="off" className="form">
          <Box mb={2}>
            <TextField
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="outlined-multiline-static"
              label="Address"
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="outlined-basic"
              label="Postcode"
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField id="outlined-basic" label="Email" variant="outlined" />
          </Box>
          <Box mb={2}>
            <TextField
              id="outlined-basic"
              type="number"
              label="Phone"
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="outlined-multiline-static"
              label="Enquiry"
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
          <Box>
            <button className="btn btn-default">SEND</button>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
