import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import linkResolver from '../../helpers/linkResolver';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: calc(100vh - 20vh);
  position: relative;
  overflow: hidden;
`;

function ImageGrid(props) {
  const { data } = props;
  return (
    <div className="spaced-grid-wrapper">
      <Grid container spacing={4}>
        {data.map((item, i) => {
          return (
            <Grid key={i} item xs={12} md={6}>
              <Background image={item.image.url}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box className="visibility-hidden" pt={5} />
                  <div>
                    <Typography
                      component="h3"
                      variant="h1"
                      color="textSecondary"
                      align="center"
                      gutterBottom
                    >
                      {item.title.text}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      {item.caption.text}
                    </Typography>
                  </div>
                  <Box pb={5}>
                    {item.link.uid && (
                      <Link
                        to={`${linkResolver(item.link)}${
                          item.anchor_element ? `#${item.anchor_element}` : ``
                        }`}
                        className="standard-text-link"
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          color="textSecondary"
                          className="standard-text-link-text"
                        >
                          VIEW
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Background>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

ImageGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ImageGrid;
