import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: calc(80vh);
  position: relative;
  overflow: hidden;
  display: flex;
`;

function ContentImageBlock(props) {
  const { data } = props;
  const position =
    data.content_box_position == 'Left' ? 'flex-start' : 'flex-end';
  return (
    <Container maxWidth="lg">
      <Background image={data.image.url}>
        <Box
          display="flex"
          justifyContent={{ xs: 'center', lg: position }}
          width="100%"
        >
          <Box
            width={{ xs: '100%', md: '75%', lg: '60%' }}
            py={{ xs: 3, md: 5 }}
            pr={{ xs: 3, md: 5 }}
            pl={{ xs: 3, md: 5 }}
          >
            <Box
              bgcolor="secondary.main"
              height="100%"
              p={{ xs: 5, md: 8, lg: 10 }}
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box mb={2}>
                <Typography
                  component="h3"
                  variant="h4"
                  className="text-uppercase"
                  align="center"
                  gutterBottom
                >
                  {data.title.text}
                </Typography>
              </Box>
              <Typography
                component="div"
                variant="body1"
                align="center"
                className="height-full"
              >
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-evenly"
                >
                  <RichText render={data.content.raw} />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Background>
    </Container>
  );
}

ContentImageBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContentImageBlock;
