import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

function CustomContactForm(props) {
  const { items, data } = props;
  const [formInput, setFormInput] = useReducer((state, newState) => ({
    ...state,
    ...newState,
  }));

  // Server state handling
  const [emptySubmit, setEmptySubmit] = useState('');
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    complete: false,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      complete: true,
    });
  };
  const handleEmptyResponse = msg => {
    setEmptySubmit(msg);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (formInput) {
      setEmptySubmit('');
      setServerState({ submitting: true });
      let data = { formInput };
      let formData = new FormData();
      {
        Object.keys(data.formInput).map(key => {
          formData.append(key, data.formInput[key]);
        });
      }
      axios({
        method: 'POST',
        url: `https://formspree.io/f/xvovejgq`,
        data: formData,
      }).then(() => {
        handleServerResponse(true, 'Thanks for getting in touch');
      });
    } else {
      handleEmptyResponse('Please complete all fields');
    }
  };

  const handleInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  return (
    <React.Fragment>
      <Box
        component={Grid}
        container
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid item xs={12} md={8} lg={8}>
          {data.title && (
            <Box pb={5}>
              <Typography component="h3" variant="h3" align="center">
                {data.title.text}
              </Typography>
            </Box>
          )}
          {data.caption && (
            <Box
              component="div"
              className="text-center"
              pb={5}
              dangerouslySetInnerHTML={{
                __html: data.caption.html,
              }}
            />
          )}
        </Grid>
      </Box>
      <Box>
        {serverState.submitting && (
          <Box
            //className={classes.submitting}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress />
            <Box py={1} />
            <Typography component="p" variant="h6">
              Submitting
            </Typography>
          </Box>
        )}
        {serverState.status && serverState.status.ok && (
          <Box my={4} display="flex" alignItems="center" flexDirection="column">
            <Typography component="p" variant="h6" align="center" gutterBottom>
              {serverState.status.msg}
            </Typography>
            <Typography component="p" variant="subtitle1" align="center">
              One of our team will be in contact as soon as possible
            </Typography>
          </Box>
        )}
      </Box>
      {emptySubmit && (
        <Box mb={4}>
          <Typography align="center" component="p" className="text-red">
            {emptySubmit}
          </Typography>
        </Box>
      )}
      <Box component={Grid} container display="flex" justifyContent="center">
        <Grid item xs={12} md={8} lg={4}>
          {!serverState.complete && !serverState.submitting && (
            <form onSubmit={handleSubmit}>
              {items.map((item, key) => {
                switch (item.field_types) {
                  case 'Textfield':
                    return (
                      <div key={key}>
                        <Box mb={4}>
                          <TextField
                            id="outlined-basic"
                            label={item.field_name}
                            name={item.field_name}
                            variant="outlined"
                            onChange={handleInput}
                          />
                        </Box>
                      </div>
                    );
                  case 'Textarea':
                    return (
                      <div key={key}>
                        <Box mb={4}>
                          <TextField
                            id="standard-multiline-static"
                            label={item.field_name}
                            name={item.field_name}
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={handleInput}
                          />
                        </Box>
                      </div>
                    );
                  case 'Score':
                    return (
                      <div key={key}>
                        <Box mb={4}>
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-age-native-simple">
                              {item.field_name}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              name={item.field_name}
                              label={item.field_name}
                              onChange={handleInput}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    );
                  default:
                    return null;
                }
              })}
              <Box display="flex" justifyContent="center">
                <input
                  className="btn btn-default"
                  type="submit"
                  value="SUBMIT"
                />
              </Box>
            </form>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

CustomContactForm.propTypes = {
  data: PropTypes.object,
  items: PropTypes.array.isRequired,
};

export default CustomContactForm;
