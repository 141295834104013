import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Consumer } from 'store/createContext';
import Modal from 'containers/modal';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: calc(100vh - 20vh);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

function Videos(props) {
  const { data } = props;
  const [embedvideo, setEmbedvideo] = useState('');
  const setVideo = item => {
    setEmbedvideo(item.video.html);
  };
  return (
    <React.Fragment>
      <Modal>
        <div
          className="iframe-video-container"
          dangerouslySetInnerHTML={{ __html: embedvideo }}
        />
      </Modal>
      <div className="spaced-grid-wrapper">
        <Box
          component={Grid}
          container
          display="flex"
          justifyContent="center"
          spacing={4}
        >
          {data.map((item, key) => {
            return (
              <React.Fragment key={key}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  md={key === 0 ? 10 : 6}
                  lg={key === 0 ? 8 : 6}
                >
                  <Background image={item.image.url}>
                    <Box pt={5} />
                    <Box>
                      <Typography
                        color="textSecondary"
                        className="text-uppercase"
                        component="p"
                        variant={`${key === 0 ? 'h1' : 'h4'}`}
                        align="center"
                      >
                        {item.title.text}
                      </Typography>
                    </Box>
                    <Box pb={5}>
                      <Consumer>
                        {({ showModal }) => (
                          <button
                            className="standard-btn"
                            onClick={() => {
                              setVideo(item);
                              showModal();
                            }}
                          >
                            <Typography
                              variant="body1"
                              component="span"
                              color="textSecondary"
                              className="standard-text-link-text"
                            >
                              VIEW
                            </Typography>
                          </button>
                        )}
                      </Consumer>
                    </Box>
                  </Background>
                  <Box pt={4} mb={5}>
                    <Typography variant="body2" component="p" align="center">
                      {item.caption.text}
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </div>
    </React.Fragment>
  );
}

Videos.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Videos;
