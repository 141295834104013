import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import Link from 'gatsby-link';
import linkResolver from '../../helpers/linkResolver';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: calc(80vh);
  position: relative;
  overflow: hidden;
  display: flex;
`;

function ImageTextOverlay(props) {
  const { data } = props;
  return (
    <Container maxWidth="lg">
      <Background image={data.image.url}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Box
            width={{ xs: '100%', md: '50%' }}
            py={{ xs: 3, md: 10 }}
            pr={{ xs: 3, md: 10 }}
            pl={{ xs: 3, md: 0 }}
          >
            <Box
              bgcolor="secondary.main"
              height="100%"
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box width={{ xs: '100%', lg: '50%' }}>
                <Typography
                  component="h3"
                  variant="h2"
                  className="text-uppercase"
                  align="left"
                >
                  {data.title.text}
                </Typography>
              </Box>
              <Typography component="div" variant="body2" align="left">
                <RichText render={data.content.raw} />
              </Typography>
              <Box>
                {data.link && (
                  <Link to={`${linkResolver(data.link)}`}>
                    <Typography
                      variant="body2"
                      component="span"
                      color="textPrimary"
                    >
                      Find out more
                    </Typography>
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Background>
    </Container>
  );
}

ImageTextOverlay.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImageTextOverlay;
